import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from "jquery"
// import Popper from 'popper.js';
import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { BrowserRouter } from 'react-router-dom';

const App = React.lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={FallBackImg}>
      <App />
    </Suspense>
  </BrowserRouter>

);

function FallBackImg() {
  return (
    <h1>Loading...</h1>
  )
}


